<template>
  <div class="register-panel">
    <div class="d-block">
      <HeaderPanel title="เปลี่ยนเวลา" :hasFilter="false" class="mb-3" />
      <div>
        <div class="d-flex text-black w-100">
          <div class="w-100">
            <InputTextMask
              textFloat="กรุณาระบุวันเวลา"
              placeholder="dd/mm/yyyy hh:mm"
              :value="form.changeTime"
              mask="##/##/#### ##:##"
              masked
              :isError="$v.form.changeTime.$error"
              @onDataChange="changeTime"
            >
              <template #text>
                <u class="text-gray pointer" @click="updateTime"
                  >อัพเดทเวลาโลก</u
                >
              </template>
            </InputTextMask>
          </div>
        </div>
        <div v-if="$v.form.changeTime && $v.form.changeTime.$error">
          <span
            v-if="$v.form.changeTime.required == false"
            class="text-r-12 text-error"
          >
            กรุณากรอกเวลาที่ปรับต้อง
          </span>
          <span
            v-else-if="$v.form.changeTime.minValue == false"
            class="text-r-12 text-error"
          >
            เวลาที่ปรับต้องไม่น้อยกว่าวันที่
            {{ $moment(allowDay).format('DD MMMM YYYY HH:mm') }}
          </span>
          <span
            v-else-if="$v.form.changeTime.maxValue == false"
            class="text-r-12 text-error"
          >
            เวลาที่ปรับต้องไม่มากกว่าเวลาปัจจุบัน
          </span>
          <span
            v-else-if="$v.form.changeTime.inValidTime == false"
            class="text-r-12 text-error"
          >
            วันที่และเวลาไม่ถูกต้อง กรุณาระบุเป็น ปี พ.ศ. (dd/mm/yyyy hh:mm)
          </span>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between w-100 my-0 mobile-footer">
      <b-button
        variant="close"
        class="btn-modal btn-hover w-50 mr-1"
        @click="$router.push('/')"
        :disabled="isLoading"
      >
        ยกเลิก
      </b-button>
      <b-button
        variant="primary-color"
        class="btn-modal btn-hover w-50 ml-1"
        @click="save"
        :disabled="isLoading"
      >
        ยืนยัน
      </b-button>
    </div>
  </div>
</template>

<script>
import InputTextMask from '@/components/inputs/InputTextMask';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { TheMask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import HeaderPanel from '@/components/HeaderPanel';

export default {
  components: {
    InputTextMask,
    TheMask,
    HeaderPanel
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { changeTime: '' },
      today: null,
      allowDay: null,
      allowTimeChange: {
        result: 0,
        allowDay: 0,
        allowMonth: 0
      }
    };
  },
  validations() {
    return {
      form: {
        changeTime: {
          required,
          minValue(value) {
            if (this.$moment(value, 'DD/MM/YYYY HH:mm').isValid()) {
              let dateTime = this.$moment(value, 'DD/MM/YYYY HH:mm').unix();
              let allowDay = this.$moment(this.allowDay).unix();
              return dateTime >= allowDay;
            }
            return true;
          },
          maxValue(value) {
            if (this.$moment(value, 'DD/MM/YYYY HH:mm').isValid()) {
              let dateTime = this.$moment(value, 'DD/MM/YYYY HH:mm').unix();
              let now = this.$moment().add(543, 'years').unix();
              return dateTime <= now;
            }
            return true;
          },
          inValidTime(value) {
            return value && this.$moment(value, 'DD/MM/YYYY HH:mm').isValid();
          }
        }
      }
    };
  },
  async created() {
    await this.getAllowTimeChange();
    this.today = this.$moment().add(543, 'years').format('DD/MM/YYYY HH:mm');
    let dateTime = this.today;
    if (this.dateTimeFix) {
      dateTime = this.$moment(this.dateTimeFix)
        .add(543, 'years')
        .format('DD/MM/YYYY HH:mm');
    } else {
      dateTime = this.$moment().add(543, 'years').format('DD/MM/YYYY HH:mm');
    }
    this.form.changeTime = dateTime;
    this.allowDay = this.$moment().add(
      this.allowTimeChange.allowDay * -1,
      'days'
    );
    this.allowDay = this.$moment(this.allowDay)
      .add(543, 'years')
      .format('YYYY-MM-DD HH:mm:ss');
  },
  computed: {
    ...mapGetters({
      dateTimeFix: 'getDateTimeFix',
      branchId: 'getBranchId'
    })
  },
  methods: {
    updateTime() {
      this.form.changeTime = this.$moment()
        .add(543, 'years')
        .format('DD/MM/YYYY HH:mm');
    },
    async getAllowTimeChange() {
      let branchId = this.$cookies.get('branch_id');
      if (branchId) {
        await this.axios
          .get(`${this.$baseUrl}/transaction/get_allow_time_change/${branchId}`)
          .then(async data => {
            if (data.result == 1) {
              this.allowTimeChange.result = data.detail.result;
              this.allowTimeChange.allowDay = data.detail.allow_day;
              this.allowTimeChange.allowMonth = data.detail.allow_month;
            }
          });
      }
    },
    changeTime(val) {
      this.form.changeTime = val;
    },
    async save() {
      if (this.allowTimeChange.result == 0) {
        this.warningAlert('ไม่สามารถย้อนเวลาได้');
      } else {
        this.$v.form.$touch();
        if (
          this.$v.form.$error ||
          !this.$moment(this.form.changeTime, 'DD/MM/YYYY HH:mm').isValid()
        )
          return;

        this.isLoading = true;
        let dateTime = await this.$moment(
          this.form.changeTime,
          'DD/MM/YYYY HH:mm'
        )
          .add(-543, 'years')
          .format('YYYY-MM-DDTHH:mm:ss');
        try {
          this.isLoading = false;
          await this.$store.dispatch('setDateTimeFix', dateTime);
          await this.successAlert();
          this.$router.push('/');
        } catch (error) {
          this.warningAlert(error);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  box-shadow: 0 -10px 20px -5px #737373bf;
  button {
    width: 100%;
  }
}

.register-panel {
  padding-bottom: 85px;
}

::v-deep {
  .title {
    display: flex;
    justify-content: space-between;
  }
}
</style>
